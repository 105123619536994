import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { VisibilityObserver } from 'react-is-visible';
import useElementOnScreen from '../../hooks/useElementOnScreen';
import useHasScrolledPassed from '../../hooks/useHasScrolledPassed';
import appearance, { appearanceStyle } from '../../utils/appearance';
import ContentItem from '../ContentItem';

VisibilityObserver.setIntersectionObserverOptions({ threshold: 0.9 });

const getRootEl = (el) => {
	if (el.parentNode.classList.contains('animate__animated')) {
		return el.parentNode;
	}

	return el;
};

const getTitleEl = (el) => {
	const rootEl = getRootEl(el);

	if (rootEl.classList.contains('animate__animated')) {
		return rootEl.previousSibling;
	}

	return rootEl?.previousSibling?.previousSibling;
};

const Section = ({ className, section, children }) => {
	const nodeRef = useRef();
	const [folderPosition, setFolderPosition] = useState(0);
	const hasScrolledPassed = useHasScrolledPassed(nodeRef, folderPosition);
	const isVisible = useElementOnScreen(nodeRef, { threshold: 0.2 });

	useLayoutEffect(() => {
		if (section?.custom?.style === 'folder') {
			const folders = document.querySelectorAll('.section.folder');
			const firstFolder = folders[0];
			const handleScroll = () => {
				const currentTitle = getTitleEl(nodeRef.current);
				const style = getComputedStyle(currentTitle);
				const height = parseFloat(style.getPropertyValue('--height'));

				const totalHeight = folders.length * height;

				const offset = Math.min(window.scrollY, totalHeight);
				const index = [...folders].indexOf(nodeRef.current);

				setFolderPosition(-height * index - 60);

				if (firstFolder !== nodeRef.current) {
					return;
				}

				folders.forEach((folder, index) => {
					const el = getTitleEl(folder);

					el.style.bottom = `${-((index + 1) * height) + offset}px`;

					if (offset === totalHeight) {
						el.classList.add('sticky-bottom');
					} else {
						el.classList.remove('sticky-bottom');
					}
				});
			};

			document.addEventListener('scroll', handleScroll);

			return () => {
				document.removeEventListener('scroll', handleScroll);
			};
		}
	}, []);

	useLayoutEffect(() => {
		if (section?.custom?.style === 'folder') {
			const folders = document.querySelectorAll('.section.folder');
			const [firstFolder = null] = folders;
			const lastFolder = folders[folders.length - 1];

			folders.forEach((folder) => {
				const el = getTitleEl(folder);
				if (el && !el.classList.contains('folder-title')) {
					el.classList.add('folder-title');
				}
			});

			const thisTitle = getTitleEl(nodeRef.current);

			if (thisTitle) {
				if (hasScrolledPassed) {
					thisTitle.classList.add('has-scrolled-passed');
				} else {
					thisTitle.classList.remove('has-scrolled-passed');
				}
			}

			if (
				lastFolder === nodeRef.current &&
				hasScrolledPassed &&
				!document.body.classList.contains('passed-folders')
			) {
				document.body.classList.add('passed-folders');
				folders.forEach((folder, index) => {
					const el = getTitleEl(folder);
					const style = getComputedStyle(el);
					const height = parseFloat(
						style.getPropertyValue('--height'),
					);

					const offset = parseFloat(
						style.getPropertyValue('--offset'),
					);
					const title = el.getBoundingClientRect();
					el.style.top = `${
						-title.top + index * 2 * height + offset * 2
					}px`;
				});
			} else if (
				lastFolder === nodeRef.current &&
				!hasScrolledPassed &&
				document.body.classList.contains('passed-folders')
			) {
				document.body.classList.remove('passed-folders');
				folders.forEach((folder) => {
					const el = getTitleEl(folder);
					el.style.top = null;
				});
			}

			if (firstFolder && nodeRef.current === firstFolder) {
				if (hasScrolledPassed) {
					document.body.classList.add('hide-navigation');
				} else {
					document.body.classList.remove('hide-navigation');
				}
			}
		}
	}, [hasScrolledPassed]);

	useLayoutEffect(() => {
		const hasBgColor = section?.custom?.animateIn?.find((item) =>
			item.startsWith('bg-'),
		);

		let main = document.querySelector('main');

		if (section?.custom?.style === 'folder') {
			main = nodeRef.current;
		}

		if (!hasBgColor) {
			return;
		}

		const hex = hasBgColor.startsWith('bg-[#') && hasBgColor.split('bg-[');

		if (isVisible) {
			if (hex) {
				main.style.backgroundColor = hex[1].substring(
					0,
					hex[1].length - 1,
				);
			} else {
				main.classList.add(hasBgColor);
			}
		} else if (!isVisible) {
			if (hasBgColor.startsWith('bg-[#')) {
				if (main.style.backgroundColor === hex) {
					main.style.backgroundColor = null;
				}
			} else {
				main.classList.remove(hasBgColor);
			}
		}
	}, [isVisible]);

	return (
		<>
			<div className="scroll-to" id={section?.sectionName}></div>
			<div
				ref={nodeRef}
				className={classNames(
					className,
					'section',
					appearance(section?.custom, {
						height: 'lg:h-screen',
						padding: 'py-8 md:py-16 lg:py-32',
					}),
					section?.custom?.position,
					'flex',
					'flex-col',
					'justify-center',
					{
						'has-scrolled-passed': hasScrolledPassed,
					},
				)}
				style={appearanceStyle(section?.custom)}
			>
				<div
					className={classNames(
						className,
						`container`,
						section?.custom?.container,
						section?.custom?.padding,
						section?.custom?.position,
						`mx-auto`,
						'flex',
						'flex-col',
					)}
				>
					{!!section?.title && (
						<h2
							className={classNames(
								`section-title font-medium text-2xl mb-0 md:mb-8 lg:mb-8`,
							)}
						>
							{section?.title || ' '}
						</h2>
					)}
					{section?.items?.map?.((item) => {
						return (
							<ContentItem
								key={item.id}
								nodeId={item.contentful_id}
							/>
						);
					})}
					{children}
				</div>
			</div>
		</>
	);
};
Section.displayName = 'Section';
export default Section;
