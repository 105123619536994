import classNames from 'classnames';
import React from 'react';
import appearance, { appearanceStyle } from '../../utils/appearance';
import ContentItem from '../ContentItem';

const Group = ({ className, group, root }) => {
	return (
		<div
			className={classNames(
				className,
				`group`,
				`mx-auto`,
				appearance(group?.custom, {}),
				group?.custom?.container,
				// 'w-full',
				'flex',
				'flex-col',
			)}
			style={appearanceStyle(group?.custom)}
			id={group?.groupName}
		>
			{group?.add?.map?.((item) => {
				return (
					<ContentItem
						key={item.id}
						nodeId={item.contentful_id}
						root={root}
					/>
				);
			})}
		</div>
	);
};
Group.displayName = 'Group';
export default Group;
