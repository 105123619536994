import classNames from 'classnames';

const textAlign = (
	{ textAlign: style } = {},
	{ textAlign: defaultStyle } = {},
) => (style ? style : defaultStyle);
const size = ({ size: style } = {}, { size: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const color = ({ color: style } = {}, { color: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const fontColor = (
	{ fontColor: style } = {},
	{ fontColor: defaultStyle } = {},
) => (style ? style : defaultStyle);
const font = ({ font: style } = {}, { font: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const transform = (
	{ transform: style } = {},
	{ transform: defaultStyle } = {},
) => (style ? style : defaultStyle);
const decoration = (
	{ decoration: style } = {},
	{ decoration: defaultStyle } = {},
) => (style ? style : defaultStyle);
// const variant = (
// 	{ variant: style } = {},
// 	{ variant: defaultStyle } = {},
// ) => (style ? style : defaultStyle);
const spacing = ({ spacing: style } = {}, { spacing: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const truncate = ({ truncate: style } = {}, { truncate: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const horizontalAlign = (
	{ horizontalAlign: style } = {},
	{ horizontalAlign: defaultStyle } = {},
) => (style ? style : defaultStyle);
const verticalAlign = (
	{ verticalAlign: style } = {},
	{ verticalAlign: defaultStyle } = {},
) => (style ? style : defaultStyle);
const direction = (
	{ direction: style } = {},
	{ direction: defaultStyle } = {},
) => (style ? style : defaultStyle);
const padding = ({ padding: style } = {}, { padding: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const margin = ({ margin: style } = {}, { margin: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const borderRadius = (
	{ borderRadius: style } = {},
	{ borderRadius: defaultStyle } = {},
) => (style ? style : defaultStyle);
const border = ({ border: style } = {}, { border: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const width = ({ width: style } = {}, { width: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const height = ({ height: style } = {}, { height: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const shadow = ({ shadow: style } = {}, { shadow: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const order = ({ order: style } = {}, { order: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const gap = ({ gap: style } = {}, { gap: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const opacity = ({ opacity: style } = {}, { opacity: defaultStyle } = {}) =>
	style ? style : defaultStyle;
// const style = ({ style } = {}, { style: defaultStyle } = {}) =>
// 	style ? style : defaultStyle;
const style = ({ style } = {}, { style: defaultStyle } = {}) => {
	const data = style ? style : defaultStyle;

	if (data === 'odd') {
		return 'odd';
	}

	if (data === 'even') {
		return 'even';
	}

	if (data === 'background') {
		return 'background';
	}

	if (data === 'surface') {
		return 'surface';
	}

	if (data === 'card') {
		return 'card';
	}

	if (data === 'dark') {
		return 'dark';
	}

	if (data === 'primary') {
		return 'primary';
	}

	return data;
};

const buttonVariant = (
	{ buttonVariant: style } = {},
	{ buttonVariant: defaultStyle } = {},
) => {
	const data = style ? style : defaultStyle;

	if (data === 'primary') {
		return 'btn-primary';
	}

	if (data === 'secondary') {
		return 'btn-secondary';
	}

	if (data === 'link') {
		return 'btn-link';
	}

	return data;
};

const variant = (
	{ variant: style } = {},
	{ variant: defaultStyle, component } = {},
) => {
	//rename variant to size ?!?!
	const data = style ? style : defaultStyle;

	if (component === 'button') {
		if (data === 'large') {
			return 'btn-large';
		}

		if (data === 'medium') {
			return 'btn-medium';
		}

		if (data === 'small') {
			return 'btn-small';
		}
	}

	if (component === 'title') {
		if (data === 'huge') {
			return 'title-size-huge';
		}

		if (data === 'xl') {
			return 'title-size-xl';
		}

		if (data === 'large') {
			return 'title-size-lg';
		}

		if (data === 'medium') {
			return 'title-size-medium';
		}

		if (data === 'small') {
			return 'title-size-small';
		}

		if (data === 'xs') {
			return 'title-size-xs';
		}
	}

	if (component === 'text') {
		if (data === 'xl') {
			return 'text-size-xl';
		}

		if (data === 'huge') {
			return 'text-size-huge';
		}

		if (data === 'large') {
			return 'text-size-lg';
		}

		if (data === 'medium') {
			return 'text-size-base';
		}

		if (data === 'small') {
			return 'text-size-sm';
		}

		if (data === 'xs') {
			return 'text-size-xs';
		}
	}

	if (component === 'icon') {
		if (data === 'xl') {
			return 'icon-xl';
		}

		if (data === 'large') {
			return 'icon-large';
		}

		if (data === 'medium') {
			return 'icon-medium';
		}

		if (data === 'small') {
			return 'icon-small';
		}

		if (data === 'xs') {
			return 'icon-xs';
		}
	}

	return data;
};

const animate = ({ animate: style } = {}, { animate: defaultStyle } = {}) => {
	if (!style && !defaultStyle) {
		return undefined;
	}

	const animate = style || defaultStyle;

	return [
		'wow',
		...(Array.isArray(animate) ? animate : [animate]).map(
			(item) => `animate__${item}`,
		),
	];
};

const overflow = ({ overflow: style } = {}, { overflow: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const gradient = ({ opacity: style } = {}, { opacity: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const zIndex = ({ opacity: style } = {}, { opacity: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const position = ({ opacity: style } = {}, { opacity: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const translate = ({ opacity: style } = {}, { opacity: defaultStyle } = {}) =>
	style ? style : defaultStyle;
const background = (
	{ background: style } = {},
	{ background: defaultStyle } = {},
) => (style ? style : defaultStyle);

export default (appearance, defaults = {}) => {
	const app = appearance ?? {};
	return classNames(
		// type(app),
		app?.class,
		horizontalAlign(app, defaults),
		verticalAlign(app, defaults),
		direction(app, defaults),
		textAlign(app, defaults),
		background(app, defaults),
		padding(app, defaults),
		margin(app, defaults),
		height(app, defaults),
		borderRadius(app, defaults),
		border(app, defaults),
		width(app, defaults),
		shadow(app, defaults),
		order(app, defaults),
		gap(app, defaults),
		opacity(app, defaults),
		overflow(app, defaults),
		gradient(app, defaults),
		size(app, defaults),
		color(app, defaults),
		fontColor(app, defaults),
		font(app, defaults),
		decoration(app, defaults),
		spacing(app, defaults),
		truncate(app, defaults),
		zIndex(app, defaults),
		position(app, defaults),
		transform(app, defaults),
		translate(app, defaults),
		style(app, defaults),
		variant(app, defaults),
		buttonVariant(app, defaults),
		// animate(app, defaults),
	);
};

export const appearanceStyle = (appearance, defaults = {}) => {
	const app = appearance ?? {};
	return {
		backgroundColor: app.backgroundColor,
		fontColor: app.fontColor,
	};
};
