import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import appearance, { appearanceStyle } from '../../utils/appearance';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import classNames from 'classnames';
import { Link } from 'gatsby-plugin-intl';

const trailingSlash = (path) => {
	if (!path)  {
		return path;
	}

	if (path.startsWith('#')) {
		return path;
	}

	if (!path.endsWith('/')) {
		return `/${path}/`;
	}

	return `/${path}`;
};

const Text = ({ item }) => {
	if (!item?.text?.raw) {
		return null;
	}

	return (
		<div
			className={classNames(
				'content',
				'text',
				'w-full',
				appearance(item, {
					component: 'text',
					variant: 'medium',
					margin: 'mb-8',
				}),
			)}
			style={appearanceStyle(item)}
		>
			{documentToReactComponents(JSON.parse(item?.text?.raw))}
		</div>
	);
};

const TitleElement = ({ item }) => {
	return (
		<h2
			className={classNames(
				appearance(item, {
					component: 'title',
					variant: 'medium',
					margin: 'mb-4',
				}),
				'title',
				{
					[`text-${item.align}`]: item.align,
				},
			)}
			style={appearanceStyle(item)}
		>
			{item?.title}
		</h2>
	);
};

function scrollToTargetAdjusted(e, query) {
	var element = document.getElementById(query.substring(1));

	if (!element) {
		return;
	}

	const anchor = e.target.parentNode;

	let offset = 0;
	if (anchor.classList.contains('folder-title')) {
		const folders = document.querySelectorAll('.folder-title');
		const index = [...folders].indexOf(anchor);
		const style = getComputedStyle(folders[0]);
		const height = parseFloat(style.getPropertyValue('--height'));
		offset = height * (index + 1);
	}

	var elementPosition = element.getBoundingClientRect().top;
	var offsetPosition = elementPosition + window.pageYOffset - offset;

	window.scrollTo({
		top: offsetPosition,
		behavior: 'smooth',
	});
}

const Title = ({ item }) => {
	const { url } = item;

	if (url) {
		if (url.startsWith('#')) {
			return (
				<a
					href={url}
					onClick={(e) => {
						e.preventDefault();
						scrollToTargetAdjusted(e, url);
					}}
				>
					<TitleElement item={item} />
					<ChevronUpIcon className="icon-up h-5 w-5 relative dark:text-gray-500" />
					<ChevronDownIcon className="icon-down h-5 w-5 relative dark:text-gray-500" />
				</a>
			);
		}
		return (
			<Link to={trailingSlash(url)}>
				<TitleElement item={item} />
			</Link>
		);
	}

	return <TitleElement item={item} />;
};

export default Text;
export { Text, Title };
