import React, { useMemo } from 'react';
import Grid from '../Grid/Grid';
import Section from '../Section/Section';

const TemplateColumn = ({ section, root }) => {
	const grid = useMemo(() => {
		const items = [];
		if (section?.column1) {
			items.push({
				id: 'column1',
				add: section?.column1,
				custom: {
					horizontalAlign: 'justify-center',
					verticalAlign: 'items-start',
				},
			});
		}

		if (section?.column2) {
			items.push({
				id: 'column2',
				add: section?.column2,
				custom: {
					horizontalAlign: 'justify-center',
					verticalAlign: 'items-start',
				},
			});
		}

		if (section?.column3) {
			items.push({
				id: 'column3',
				add: section?.column3,
				custom: {
					horizontalAlign: 'justify-center',
					verticalAlign: 'items-start',
				},
			});
		}

		if (section?.column4) {
			items.push({
				id: 'column4',
				add: section?.column4,
				custom: {
					horizontalAlign: 'justify-center',
					verticalAlign: 'items-start',
				},
			});
		}

		return {
			add: items,
		};
	}, [section]);

	if (root) {
		return (
			<Section section={section}>
				<TemplateColumn section={section} />
			</Section>
		);
	}

	return <Grid grid={grid} />;
};

export default TemplateColumn;
