import classNames from 'classnames';
import React from 'react';
import appearance, { appearanceStyle } from '../../utils/appearance';
import SectionImage from '../SectionImage/SectionImage';

const TemplateLogos = ({ section, className }) => {
	return (
		<div
			className={classNames(
				'container mx-auto flex justify-center py-28',
				appearance(section.custom),
			)}
			style={appearanceStyle(section?.custom)}
		>
			<div className="top flex flex-col justify-center items-center text-center">
				<h2 className={classNames(`mb-8`)}>{section?.title}</h2>

				{section.subTitle ? (
					<p className="lead">{section.subTitle}</p>
				) : null}

				<SectionImage
					title={section?.image?.title}
					image={section?.image?.url}
				/>
			</div>
		</div>
	);
};

export default TemplateLogos;
