import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SocialIcon } from 'react-social-icons';
import ContentItem from '../ContentItem';
import { Link } from 'gatsby-plugin-intl';

import { ArrowUpIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

const trailingSlash = (path) => {
	if (!path) {
		return path;
	}
	if (path.includes('#')) {
		return path;
	}
	if (!path.endsWith('/')) {
		return `/${path}/`;
	}
	if (path.startsWith('https://') || path.startsWith('http://')) {
		return path;
	}
	if (path.startsWith('/')) {
		return path;
	}
	return path;
	// return `/${path}`;
};

const Footer = () => {
	const response = useStaticQuery(graphql`
		query SiteFooter {
			contentfulFooter {
				id
				name
				copyright
				nav {
					name
					id
					variant
					url
					text
					target
					size
				}
				add {
					add {
						... on ContentfulColumn {
							id
							contentful_id
							column
							add {
								__typename
								... on ContentfulReference {
									id
									contentful_id
								}
								... on ContentfulTitle {
									name
									title
									size
									truncate
									textAlign
									color
									size
									font
									transform
									fontColor
									decoration
									spacing
									margin
									padding
									height
									width
									truncate
									style
									variant
									url
								}
								... on ContentfulNav {
									id
									contentful_id
									name
									class
									variant
									url
									content: text
									target
								}
							}
						}
					}
				}
			}
		}
	`);

	const { contentfulFooter: footer } = response;

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const [isTop, setIsTop] = useState(true);
	useEffect(() => {
		const checkIsTop = () => {
			if (window.scrollY === 0) {
				setIsTop(true);
			} else {
				setIsTop(false);
			}
		};

		checkIsTop();

		document.addEventListener('scroll', checkIsTop);

		return () => document.removeEventListener('scroll', checkIsTop);
	}, []);

	return (
		<>
			<div className="footer flex flex-col items-center justify-center w-full gap-4 bg-slate-900 lg:px-0 xl:px-32 xl:pt-20 md:z-10 md:relative">
				<button
					className={classNames(
						'fixed left-4 bottom-4 z-30 rounded-full bg-white shadow w-10 h-10 justify-center items-center flex btn-up',
						{
							hidden: isTop,
						},
					)}
					onClick={scrollToTop}
				>
					<ArrowUpIcon className="h-6 w-6 dark:text-gray-500" />
				</button>
				<div className="md:border border-slate-800 py-16 px-8 xl:py-20 rounded-3xl container gap-16 min-w-full">
					<div className="flex justify-evenly flex-col lg:flex-row w-full mt-auto mb-auto gap-16 lg:gap-0">
						{footer?.add?.add?.map((item, index) => {
							return (
								<div
									className={`w-full ${index !== 0 && ''}`}
									key={item?.contentful_id}
								>
									<ul className="flex m-0 p-0 lg:px-8 gap-2 flex-col">
										{item?.add.map((data, index) => {
											return (
												<li
													key={index}
													className="text-slate-500 text-size-md hover:text-white"
												>
													<ContentItem
														key={data.id}
														item={data}
													/>
												</li>
											);
										})}
									</ul>
								</div>
							);
						})}
					</div>
				</div>

				<div className="flex flex-col lg:flex-row gap-8 px-8 xl:p-0 lg:gap-16 justify-around lg:items-center xl:my-12 w-full lg:w-auto">
					{footer?.nav?.map(({ id, text, url }) =>
						url.startsWith('http') ? (
							<a
								key={id}
								href={url}
								target="_blank"
								className="p-0 m-0 text-slate-500 text-size-sm"
								rel="noreferrer"
							>
								{text}
							</a>
						) : (
							<Link
								key={id}
								to={trailingSlash(url)}
								className="p-0 m-0 text-slate-500 text-size-sm"
							>
								{text}
							</Link>
						),
					)}
				</div>

				<div className="social-icons flex py-4 mb-4">
					<SocialIcon
						url="https://www.linkedin.com/company/asteria-ab/"
						fgColor="rgb(100, 116, 139)"
						bgColor="transparent"
					/>

					<SocialIcon
						url="https://www.instagram.com/asteria.ai/"
						fgColor="rgb(100, 116, 139)"
						bgColor="transparent"
					/>

					<SocialIcon
						url="https://www.facebook.com/asteriainc/"
						fgColor="rgb(100, 116, 139)"
						bgColor="transparent"
					/>

					<SocialIcon
						url="mailto:info@asteria.ai"
						fgColor="rgb(100, 116, 139)"
						bgColor="transparent"
					/>
				</div>

				<div className="copyright flex flex-col justify-center text-center text-size-sm text-slate-500 mb-20 gap-8">
					<p>Sveavägen 45, 11134, Stockholm, Sweden</p>
					<p>© Asteria AB {new Date().getFullYear()}</p>
				</div>
			</div>
		</>
	);
};

export default Footer;
