import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SectionButton from '../SideSection/SectionButton/SectionButton';
import classNames from 'classnames';

const TemplateHero = ({ position, section, className }) => {
	let justifyStyle = {
		Left: 'start',
		Center: 'center',
		Right: 'end',
	};
	let alignItemsStyle = {
		Top: 'start',
		Middle: 'center',
		Bottom: 'end',
	};
	let widthClasses = {
		'70%': 'w-[70%]',
	};
	let heightStyle = {
		'100vh': 'screen',
		'80vh': `[80vh]`,
	};
	let buttonStyles =
		'bg-[#E3E3E3] active:bg-[#BFBFBF] hover:bg-[#BFBFBF] text-black active:text-white hover:text-white first:rounded-l-full last:rounded-r-full mr-1 py-2 pr-5 pl-5';
	return (
		<div
			id={section?.cssId}
			className={`container max-w-[100vw] bg-[${
				section?.custom?.backgroundColor
			}] h-${heightStyle[section?.custom?.height]} flex flex-${
				section?.custom?.direction?.includes('Vertical') ? 'col' : 'row'
			} justify-${
				justifyStyle[section?.custom?.horizontalAlign || 'Left']
			} items-${
				alignItemsStyle[section?.custom?.verticalAlign || 'Middle']
			}
			${!section?.name?.toLowerCase()?.includes('main') && 'pt-32'}
			`}
		>
			<div
				className={`container mx-auto flex justify-${
					justifyStyle[section?.custom?.horizontalAlign || 'Left']
				}`}
			>
				<div
					className={
						position +
						` ${
							widthClasses[section?.custom?.cardWidth] ||
							'w-[500px]'
						} flex flex-col items-${
							justifyStyle[
								section?.custom?.horizontalAlign || 'Center'
							]
						} justify-${
							alignItemsStyle[
								section?.custom?.verticalAlign || 'Top'
							]
						} text-${section?.custom?.textAlign}`
					}
				>
					{section.title && (
						<h2 className={classNames(`mb-8`)}>{section?.title}</h2>
					)}

					{section.subTitle ? (
						<p className="lead">{section.subTitle}</p>
					) : null}

					{section.description && section.description.raw ? (
						<div
							className={
								'content items-' +
								alignItemsStyle[section?.custom?.verticalAlign]
							}
						>
							{documentToReactComponents(
								JSON.parse(section.description.raw),
							)}
						</div>
					) : null}
					{!!section.add?.find(
						(item) =>
							item.name === 'button' || item?.button?.length,
					) && (
						<SectionButton
							section={section}
							className={section?.custom?.class}
							btnClasses={buttonStyles || 'btn'}
						/>
					)}
				</div>
			</div>
			{section?.add?.find((item) => !!item?.video?.url) && (
				<div className={classNames('code-block')}>
					<div className="content">
						<div className="video-wrapper">
							<video
								className="video"
								src={
									section?.add?.find(
										(item) => !!item?.video?.url,
									)?.video?.url
								}
								autoPlay
								muted
								loop
							></video>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TemplateHero;
