import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

function useHasScrolledPassed(ref, offset = 0) {
	const [passed, setPassed] = useState(false);

	const handleScroll = useCallback(() => {
		const target = [document];
		if (!ref || !ref.current) {
			return;
		}

		if (!target?.[0]) {
			return;
		}

		const box = ref.current.getBoundingClientRect();
		const targetBox = target?.[0]?.getBoundingClientRect?.() || {
			bottom: 0,
			top: 0,
			height: window.innerHeight,
		};

		if (box.bottom + offset - targetBox.top - targetBox.height < 0) {
			setPassed(true);
		} else {
			setPassed(false);
		}
	}, [offset, ref]);

	useLayoutEffect(() => {
		const target = [document];
		if (!ref || !ref.current) {
			return () => {};
		}

		if (!target?.[0]) {
			return () => {};
		}

		target[0].addEventListener('scroll', handleScroll);

		return () => {
			target[0].removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll, ref]);

	return useMemo(() => passed, [passed]);
}

export default useHasScrolledPassed;
