import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby-plugin-intl';
import { useMediaQuery } from '../hooks/useMediaQuery';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ContentItem from './ContentItem';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	XMarkIcon,
	Bars3Icon,
} from '@heroicons/react/24/solid';

import simpleLogo from '../images/favicon-64.png';
import Forms from './Forms/Forms';

const trailingSlash = (path) => {
	if (!path) {
		return path;
	}

	if (path.includes('#')) {
		return path;
	}

	if (!path.endsWith('/')) {
		return `/${path}/`;
	}

	if (path.startsWith('https://') || path.startsWith('http://')) {
		return path;
	}

	return `/${path}`;
};

const SubHeader = ({ subNavData, setActive }) => {
	if (!subNavData?.length) {
		return null;
	}

	const [subNav] = subNavData;

	if (!subNav || subNav?.nav?.length === 0) {
		return null;
	}

	return (
		<div className="dropdown">
			<div className="dropdown-inner w-full">
				{subNav?.nav
					?.filter(({ target }) => target !== 'Dropdown')
					?.map((item, index) => (
						<div className="dropdown-item" key={index + 1}>
							<Link
								to={trailingSlash(item.url)}
								activeClassName="active-link text-gray-900 font-medium"
								onClick={() => setActive(false)}
							>
								<span key={index}>{item?.text}</span>
							</Link>
						</div>
					))}
			</div>
		</div>
	);
};

const Logo = ({ hasScrolledDown, logo }) => {
	return (
		<div className="header-logo mx-0 my-auto lg:my-0 flex flex-col">
			<Link
				to="/"
				className="flex flex-row justify-between content-center no-underline relative"
			>
				<img
					src={logo?.url}
					width="49"
					height="58"
					className={classNames(
						'w-full',
						'h-full',
						'full-logo',
						'animate__faster',
						{
							'animate__animated animate__fadeOut':
								hasScrolledDown,
							'animate__animated animate__fadeIn':
								!hasScrolledDown,
						},
					)}
				/>

				<img
					src={simpleLogo}
					width="48"
					height="48"
					className={classNames(
						'simple-logo',
						'absolute',
						'animate__faster',
						'shadow',
						'rounded-full',
						{
							'animate__animated animate__fadeIn':
								hasScrolledDown,
							'animate__animated animate__fadeOut':
								!hasScrolledDown,
						},
					)}
				/>
			</Link>
		</div>
	);
};

const NavToggle = ({ active, setActive }) => {
	return (
		<button
			className={classNames(
				'border-0 bg-transparent z-[999] absolute top-4 right-4 md:top-10 md:right-10 block xl:hidden',
				{ active: active },
			)}
			onClick={() => setActive(!active)}
		>
			{!active ? (
				<Bars3Icon className="h-5 w-5 dark:text-gray-500" />
			) : (
				<XMarkIcon className="h-5 w-5 dark:text-gray-500" />
			)}
		</button>
	);
};

const ExternalLink = (props) => {
	const { to, className, children } = props;

	if (to.includes('http://') || to.includes('https://')) {
		return (
			<a href={to} className={className} target="_blank" rel="noreferrer">
				{children}
			</a>
		);
	}

	return <Link {...props} />;
};

const NavItem = ({ item, setSelected, isSelected, setActive }) => {
	const onClick = useCallback(
		(e) => {
			if (e) {
				e.stopPropagation();
			}
			setSelected(isSelected ? null : item);
		},
		[item, isSelected, setSelected],
	);

	return (
		<li key={item?.id} className={`${item?.class} nav-item`}>
			{item?.target?.includes?.('Anchor') ? (
				<a
					href={trailingSlash(item.url)}
					className={`no-underline text-[#7e8890] btn btn-${item?.variant?.toLowerCase()} btn-${item?.size?.toLowerCase()} ${
						item?.class
					}`}
					role="button"
					tabIndex="0"
					onClick={() => setActive(false)}
				>
					{item?.add && (
						<ContentItem nodeId={item?.add?.contentful_id} />
					)}
					{item?.text && <span>{item?.text}</span>}
					{!item?.variant?.includes('Primary') &&
						item.subNav?.length && (
							<button
								className="mt-px ml-[5px]"
								onClick={onClick}
							>
								{isSelected ? (
									<ChevronUpIcon className="h-5 w-5 dark:text-gray-500" />
								) : (
									<ChevronDownIcon className="h-5 w-5 dark:text-gray-500" />
								)}
							</button>
						)}
				</a>
			) : (
				<button
					className={`no-underline text-[#7e8890] btn btn-${item?.variant?.toLowerCase()} btn-${item?.size?.toLowerCase()} ${
						item?.class
					}`}
					role="button"
					tabIndex="0"
					onClick={() => setActive(false)}
				>
					<ExternalLink
						to={trailingSlash(item.url)}
						onClick={isSelected && onClick}
						activeClassName="active-link text-gray-900 font-medium"
						partiallyActive
					>
						{item?.add && (
							<ContentItem nodeId={item?.add?.contentful_id} />
						)}
						{item?.text && (
							<span
								style={{
									pointerEvents: 'none',
								}}
							>
								{item?.text}
							</span>
						)}
					</ExternalLink>
					{!item?.variant?.includes('Primary') &&
						item.subNav?.length && (
							<button
								className="mt-px ml-[5px] btn-collapse"
								onClick={onClick}
							>
								{isSelected ? (
									<ChevronUpIcon className="h-5 w-5 dark:text-gray-500" />
								) : (
									<ChevronDownIcon className="h-5 w-5 dark:text-gray-500" />
								)}
							</button>
						)}
				</button>
			)}
			{isSelected && (
				<SubHeader subNavData={item?.subNav} setActive={setActive} />
			)}
		</li>
	);
};

const Nav = ({ nav, active, selected, setSelected, setActive }) => {
	const isSmallScreen = useMediaQuery('(max-width: 1024px)');

	return (
		<ul
			className={classNames(
				'nav',
				isSmallScreen &&
					'fixed h-screen bg-[#4d5363] inset-0 p-0 m-0 flex flex-col items-center justify-center',
				{ 'hidden xl:flex': !active },
				{ active: active },
			)}
		>
			{nav
				?.filter((item) => Object.values(item).length !== 0)
				?.map((item) => {
					return (
						<NavItem
							key={item?.id}
							item={item}
							setSelected={setSelected}
							isSelected={selected?.id === item?.id}
							setActive={setActive}
						/>
					);
				})}
		</ul>
	);
};

const NewHeader = ({ className }) => {
	const response = useStaticQuery(graphql`
		query NewHeaderQuery {
			contentfulHeader {
				name
				nav {
					url
					text
					target
					variant
					name
					id
					size
					add {
						... on Node {
							id
						}
					}
					subNav {
						name
						id
						nav {
							variant
							url
							text
							target
							size
							name
							id
							class
						}
					}
					class
				}
				logo {
					title
					url
				}
			}
		}
	`);
	const { contentfulHeader: data } = response;
	const [toggleNav, setToggleNav] = useState(false);
	const [selected, setSelected] = useState({});
	const [scrollDir, setScrollDir] = useState(null);
	const lastScroll = useRef(0);

	const [hasScrolledDown, setHasScrolledDown] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
			if (st > lastScroll.current) {
				setScrollDir('down');
			} else {
				setScrollDir('up');
			}
			lastScroll.current = st <= 0 ? 0 : st;

			if (lastScroll.current > 100) {
				setHasScrolledDown(true);
			} else {
				setHasScrolledDown(false);
			}
		};

		lastScroll.current =
			window.pageYOffset || document.documentElement.scrollTop;

		document.addEventListener('scroll', onScroll);

		return () => {
			document.removeEventListener('scroll', onScroll);
		};
	}, []);

	useEffect(() => {
		const closeNav = () => {
			setSelected({});
		};

		document.addEventListener('click', closeNav);

		return () => {
			document.removeEventListener('click', closeNav);
		};
	}, []);

	const removeSelected = useCallback(() => {
		setSelected({});
	}, [selected]);

	return (
		<header
			className={classNames(
				className,
				'header',
				`scroll-dir-${scrollDir}`,
				{
					'animate__animated animate__slideInDown':
						scrollDir === 'up',
					'animate__animated animate__slideOutUp':
						scrollDir === 'down',
					'open-nav': toggleNav,
					active: toggleNav,
				},
			)}
			onClick={removeSelected}
		>
			<div className="header-nav mx-auto flex">
				<div className="w-full flex mx-auto px-4 md:px-8">
					<Logo hasScrolledDown={hasScrolledDown} logo={data?.logo} />
					<NavToggle active={toggleNav} setActive={setToggleNav} />
					<Nav
						nav={data?.nav}
						selected={selected}
						setSelected={setSelected}
						setActive={setToggleNav}
						active={toggleNav}
					/>
					<Forms />
				</div>
			</div>
		</header>
	);
};

NewHeader.propTypes = {
	siteTitle: PropTypes.string,
	siteDescription: PropTypes.string,
};

NewHeader.defaultProps = {
	siteTitle: ``,
	siteDescription: ``,
};
NewHeader.displayName = 'Header';

export default NewHeader;
