import React from 'react';
import { Link } from 'gatsby-plugin-intl';

const trailingSlash = (path) => {
	if (!path) {
		return path;
	}

	if (path.includes('#')) {
		return path;
	}

	if (!path.endsWith('/')) {
		return `/${path}/`;
	}

	if (path.startsWith('https://') || path.startsWith('http://')) {
		return path;
	}

	if (path.startsWith('/')) {
		return path;
	}

	return `/${path}`;
};

const NavItem = ({ item }) => {
	const path = item?.url || '';
	if (path.startsWith('https://') || path.startsWith('http://')) {
		return (
			<a
				href={trailingSlash(item?.url)}
				key={item?.id}
				target={item?.target?.includes('Blank') ? '_blank' : null}
				rel="noreferrer"
			>
				<p className="mb-0">{item?.content}</p>
			</a>
		);
	}

	return (
		<Link key={item?.id} to={trailingSlash(item?.url)}>
			<p className="mb-0">{item?.content}</p>
		</Link>
	);
};

export default NavItem;
