import React from 'react';
import classNames from 'classnames';

const CodeBlock = ({ item }) => {
	return (
		<div
			className={classNames('code-block')}
			dangerouslySetInnerHTML={{
				__html: item?.code?.code,
			}}
		></div>
	);
};

export default CodeBlock;
