import React, { useContext, useEffect } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import TemplateHero from '../TemplateHero/TemplateHero';
import TemplateLogos from '../TemplateLogos/TemplateLogos';
import Tabs from '../Tabs/Tabs';
import Slides from '../Slides/Slides';
import Accordion from '../Accordion/Accordion';
import SectionImage from '../SectionImage/SectionImage';
import SectionButton from '../SideSection/SectionButton/SectionButton';
import Section from '../Section/Section';
import Grid from '../Grid/Grid';
import Context from '../../context';
import TemplateColumn from '../Templates/columns';
import Quotes from '../Quotes/Quotes';
import Group from '../Group';
import Forms from '../Forms/Forms';
import Icon from '../Icon';
import Meta from '../Meta';
import Text, { Title } from '../Typography';
import ContentLoop from '../Loop';
import NavItem from '../NavItem';
import classNames from 'classnames';
import { Link } from 'gatsby-plugin-intl';
import CodeBlock from '../Code';

const getChildren = (nodes, nodeId) => {
	const item = nodes?.[nodeId];
	let items = [];
	if (!item) {
		return items;
	}

	if (item.add && Array.isArray(item.add)) {
		items = item.add;
	}

	if (item.items && Array.isArray(item.items)) {
		items = item.items;
	}

	return items
		.map(({ contentful_id } = {}) => nodes?.[contentful_id])
		.filter((item) => item);
};

const PageContent = ({ item }) => {
	const {
		url,
		legend,
		style,
		showDate,
		title,
		excerpt,
		teaser,
		readMore,
		featuredImage,
		add,
	} = item;

	const { nodes } = useContext(Context);

	if (!teaser) {
		return (
			<div className={classNames(`page-item`, 'flex', 'flex-col')}>
				{add?.map?.((item) => (
					<AnimatedContentItem
						key={item.id}
						nodeId={item.contentful_id}
						root
					/>
				))}
				<Link to={`/${url}`}>Go to</Link>
			</div>
		);
	}

	const meta = getChildren(nodes, item.contentful_id).filter(
		({ __typename }) => __typename === 'ContentfulMeta',
	);

	return (
		<div
			className={classNames(
				`page-item`,
				{ 'page-teaser': teaser },
				'flex',
				'flex-col',
				style,
			)}
		>
			{showDate && <Meta item={meta?.[0]} />}
			{featuredImage && (
				<div className="image">
					<img src={featuredImage.url} />
				</div>
			)}
			{legend && (
				<h3 className="mb-4 legend title-size-medium title">
					{legend}
				</h3>
			)}
			<h2 className="mb-8 text-size-huge">{title}</h2>
			{excerpt && <p className="text-size-base">{excerpt}</p>}
			{readMore !== false && (
				<Link className="btn-medium btn-link btn" to={`/${url}`}>
					Read more
				</Link>
			)}
		</div>
	);
};

const ContentItem = ({ item, root }) => {
	switch (item.__typename) {
		case 'ContentfulLoop':
			return <ContentLoop item={item} root={root} />;
		case 'ContentfulSection':
			return <Section section={item} root={root} />;
		case 'ContentfulMeta':
			return <Meta item={item} root={root} />;
		case 'ContentfulGrid':
			return <Grid grid={item} root={root} />;
		case 'ContentfulIcon':
			return <Icon item={item} root={root} />;
		case 'ContentfulForm':
			return <Forms form={item} root={root} />;
		case 'ContentfulTemplateHero':
			return <TemplateHero section={item} root={root} />;
		case 'ContentfulTitle':
			return <Title item={item} />;
		case 'ContentfulImage':
			return <SectionImage image={item} root={root} />;
		case 'ContentfulGroup':
			return <Group group={item} root={root} />;
		case 'ContentfulText':
			return <Text item={item} />;
		case 'ContentfulButtons':
			return <SectionButton buttons={item} root={root} />;
		case 'ContentfulTabs':
			return <Tabs tabs={item} root={root} />;
		case 'ContentfulLogos':
			return <TemplateLogos section={item} root={root} />;
		case 'ContentfulTemplate2Columns':
		case 'ContentfulTemplate3Columns':
		case 'ContentfulTemplate4Columns':
			return <TemplateColumn section={item} root={root} />;
		case 'ContentfulQuotes':
			return <Quotes quotes={item} root={root} />;
		case 'ContentfulSlides':
			return <Slides section={item} root={root} />;
		case 'ContentfulAccordions':
			return <Accordion accordion={item} root={root} />;
		case 'ContentfulNav':
			return <NavItem item={item} root={root} />;
		case 'ContentfulPage':
			return <PageContent item={item} root={root} />;
		case 'ContentfulCode':
			return <CodeBlock item={item} root={root} />;
		default:
			console.warn('Node Type Missing', item);
			return null;
	}
};

const isAnimated = ({ custom = {}, animateIn, animateOut }) => {
	let animations = [];
	if (custom && (custom.animateIn || custom.animateOut)) {
		animations = custom.animateIn || custom.animateOut;
	} else if (animateIn || animateOut) {
		animations = animateIn || animateOut;
	}

	animations = Array.isArray(animations) ? animations : [animations];

	return animations.filter((item) => !item.startsWith('bg-')).length > 0;
};

const getInAnimation = ({ custom = {}, animateIn }) => {
	let animations = [];
	if (custom && custom.animateIn) {
		animations = custom.animateIn;
	} else if (animateIn) {
		animations = animateIn;
	}

	animations = Array.isArray(animations) ? animations : [animations];

	return animations
		.filter((item) => !item.startsWith('bg-'))
		.map((item) => (item.includes('animate__') ? item : `animate__${item}`))
		.join(' ');
};

const getOutAnimation = ({ custom = {}, animateOut }) => {
	let animations = [];
	if (custom && custom.animateOut) {
		animations = custom.animateOut;
	} else if (animateOut) {
		animations = animateOut;
	}

	animations = Array.isArray(animations) ? animations : [animations];

	return animations
		.filter((item) => !item.startsWith('bg-'))
		.map((item) => (item.includes('animate__') ? item : `animate__${item}`))
		.join(' ');
};

const getDelayAnimation = ({ custom = {}, animateDelay }) => {
	if (custom && custom.animateDelay) {
		return custom.animateDelay;
	} else if (animateDelay) {
		return animateDelay;
	}

	return;
};

const getDurationAnimation = ({ custom = {}, animateDuration }) => {
	if (custom && custom.animateDuration) {
		return custom.animateDuration;
	} else if (animateDuration) {
		return animateDuration;
	}

	return;
};

const AnimatedContentItem = ({ nodeId, item: propItem, root, debug }) => {
	const { nodes } = useContext(Context);

	const item = propItem || nodes?.[nodeId];

	if (!item) {
		return null;
	}

	if (isAnimated(item)) {
		if (root !== 'tabs') {
			return (
				<AnimationOnScroll
					animateIn={getInAnimation(item)}
					animateOut={getOutAnimation(item)}
					duration={getDurationAnimation(item)}
					delay={getDelayAnimation(item)}
					animateOnce={!getOutAnimation(item)}
				>
					<ContentItem item={item} root={root} />
				</AnimationOnScroll>
			);
		} else {
			return (
				<div
					className={classNames(
						'animate__animated',
						getInAnimation(item),
						getOutAnimation(item),
					)}
				>
					<ContentItem item={item} root={root} />
				</div>
			);
		}
	}

	return <ContentItem item={item} root={root} />;
};

export default AnimatedContentItem;
