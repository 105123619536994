import React, { useContext, useMemo } from 'react';
import Grid from '../Grid/Grid';
import Context from '../../context';

const getChildren = (nodes, nodeId) => {
	const item = nodes?.[nodeId];
	let items = [];
	if (!item) {
		return items;
	}

	if (item.add && Array.isArray(item.add)) {
		items = item.add;
	}

	if (item.items && Array.isArray(item.items)) {
		items = item.items;
	}

	return items
		.map(({ contentful_id } = {}) => nodes?.[contentful_id])
		.filter((item) => item);
};

const ContentLoop = ({ item }) => {
	const { nodes } = useContext(Context);
	const { tag, max, sort } = item;

	const renderItems = useMemo(() => {
		const items = Object.values(nodes).filter(
			({ metadata: { tags = [] } = {} }) =>
				tags.find(({ name }) => name === tag),
		);

		items.forEach((item) => {
			const itemData = nodes?.[item.contentful_id];
			const meta = getChildren(nodes, item.contentful_id).filter(
				({ __typename }) => __typename === 'ContentfulMeta',
			);

			item.$sort = '';
			if (sort === 'meta' || sort === 'published') {
				item.$sort = meta?.[0]?.date;
			}

			if (sort === 'name') {
				item.$sort = itemData?.name;
			}
		});

		return items
			.sort((a, b) => -a?.$sort?.localeCompare?.(b.$sort))
			.slice(0, max ? max : undefined);
	}, [nodes, tag, sort, max]);

	const gridSetup = useMemo(() => {
		return {
			grid: item.grid,
			// grid: 'grid grid-flow-col gap-16 auto-cols-fr',
			add: renderItems.map((item) => ({
				id: item.id,
				add: [item],
				custom: {
					horizontalAlign: 'justify-center',
					verticalAlign: 'items-center',
					width: 'w-11/12 md:w-full',
					height: 'h-full',
				},
			})),
		};
	}, [renderItems, item]);

	return <Grid grid={gridSetup} />;
};

export default ContentLoop;
