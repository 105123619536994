import React from 'react';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';
import NewHeader from '../components/newHeader';
import Footer from '../components/Footer/Footer';
import Notification from '../components/Notification/Notification';
import classNames from 'classnames';

const Layout = ({ location, children }) => {
	const paths = location.pathname.split('/');
	const className = paths.slice(2);

	return (
		<div className={classNames('layout', `page-${className.join('-')}`)}>
			<NewHeader />
			<Notification />
			<TransitionProvider location={location}>
				<TransitionViews>{children}</TransitionViews>
			</TransitionProvider>
			<Footer />
		</div>
	);
};

export default Layout;
