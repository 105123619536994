import * as SolidIcons from '@heroicons/react/24/solid';
import * as OutlineIcons from '@heroicons/react/24/outline';
import classNames from 'classnames';

import React from 'react';
import appearance, { appearanceStyle } from '../../utils/appearance';

const Icon = ({ item }) => {
	const Icons = item?.style === 'outline' ? OutlineIcons : SolidIcons;
	const AssetIcon = Icons?.[item?.asset] || null;

	if (item?.code?.code) {
		return (
			<div
				className={classNames(
					'icon',
					'html-danger',
					appearance(item?.custom),
					appearance(item, { component: 'icon', variant: 'medium' }),
					item?.iconColor,
				)}
				style={{ ...appearanceStyle(item?.custom), color: item.fill }}
				dangerouslySetInnerHTML={{ __html: item.code.code }}
			/>
		);
	}

	if (AssetIcon === null) {
		return null;
	}

	return (
		<div
			className={classNames(
				'icon',
				appearance(item?.custom),
				appearance(item, { component: 'icon', variant: 'medium' }),
				item?.iconColor,
			)}
			style={{ ...appearanceStyle(item?.custom), color: item.fill }}
		>
			<AssetIcon />
		</div>
	);
};

export default Icon;
