import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import './index.scss';

const DefaultImage = ({ className, image }) => {
	const { className: imageClassName, title, url, ...imageProps } = image;
	return (
		<div className={classNames('image', className, imageClassName)}>
			<img className="mx-auto" src={url} alt={title} {...imageProps} />
		</div>
	);
};

const CarouselImage = ({ className, gallery }) => {
	const { asset: assets } = gallery;
	const [current, setCurrent] = useState(0);
	const currentAsset = useMemo(() => assets?.[current], [assets, current]);

	const firstCol = useMemo(() => {
		const len = Math.ceil(assets.length / 2);
		return assets.slice(0, len);
	}, [assets]);

	const lastCol = useMemo(() => {
		const len = Math.ceil(assets.length / 2);
		return assets.slice(len);
	}, [assets]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (current + 1 === assets.length) {
				setCurrent(0);
			} else {
				setCurrent(current + 1);
			}
		}, 6000);

		return () => {
			clearTimeout(timer);
		};
	}, [current, assets.length]);

	return (
		<div className={classNames(className, 'gallery', 'carousel', 'grid')}>
			<div className="col-start-1 px-10 flex flex-col justify-between">
				{firstCol.map((item, index) => (
					<div
						key={item.id}
						className={classNames('gallery-carousel-item-box', {
							active: item === currentAsset,
						})}
						onClick={() => setCurrent(index)}
					>
						<h3>{item.title}</h3>
						<p>{item.description}</p>
					</div>
				))}
			</div>
			<div className="col-start-2 laptop">
				<div className="laptop-screen">
					{(assets || []).map((asset) => (
						<div
							key={asset.id}
							className={classNames('laptop-gallery-slide', {
								hidden: asset !== currentAsset,
							})}
						>
							<div className="laptop-gallery-slide-img">
								<img
									src={asset.url}
									alt="Finance department in your pocket."
								/>
							</div>
						</div>
					))}
				</div>
				<div className="laptop-bottom">
					<div className="laptop-shadow"></div>
				</div>
			</div>
			<div className="col-start-3 px-10 flex flex-col justify-between">
				{lastCol.map((item, index) => (
					<div
						key={item.id}
						className={classNames('gallery-carousel-item-box', {
							active: item === currentAsset,
						})}
						onClick={() => setCurrent(firstCol.length + index)}
					>
						<h3>{item.title}</h3>
						<p>{item.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

const SectionImage = ({ image }) => {
	if (image?.type?.defaultImage) {
		return <DefaultImage image={image?.type?.defaultImage} />;
	}

	if (image?.type?.type === 'Carousel') {
		return <CarouselImage gallery={image?.type} />;
	}

	if (image?.type?.type) {
		return null;
	}

	return null;
};
SectionImage.displayName = 'SectionImage';
export default SectionImage;
