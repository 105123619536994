import React, { memo } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby-plugin-intl';
import appearance, { appearanceStyle } from '../../../utils/appearance';
import ContentItem from '../../ContentItem';

const trailingSlash = (path) => {
	if (!path)  {
		return path;
	}

	if (path.startsWith('#')) {
		return path;
	}

	if (path.startsWith('mailto:')) {
		return path;
	}

	if (!path.endsWith('/')) {
		return `/${path}/`;
	}

	return `/${path}`;
};

function scrollToTargetAdjusted(e, query) {
	var element = document.getElementById(query.substring(1));

	if (!element) {
		return;
	}

	const anchor = e.target.parentNode;

	let offset = 0;
	if (anchor.classList.contains('folder-title')) {
		const folders = document.querySelectorAll('.folder-title');
		const index = [...folders].indexOf(anchor);
		const style = getComputedStyle(folders[0]);
		const height = parseFloat(style.getPropertyValue('--height'));
		offset = height * (index + 1);
	}

	var elementPosition = element.getBoundingClientRect().top;
	var offsetPosition = elementPosition + window.pageYOffset - offset;

	window.scrollTo({
		top: offsetPosition,
		behavior: 'smooth',
	});
}

const SectionButton = ({ button, btnClasses }) => {
	if (!button) {
		return null;
	}

	const url = button?.url || '';

	if (url.startsWith('http')) {
		return (
			<a
				role="button"
				tabIndex="0"
				className={classNames(
					btnClasses,
					appearance(button, {
						component: 'button',
						variant: 'medium',
						buttonVariant: 'primary',
					}),
					'btn',
					{
						'bg-[#BFBFBF] text-white':
							typeof window !== 'undefined' &&
							window.location.href.includes(button.url),
						[`btn-${button?.variant?.toLowerCase()}`]:
							!!button?.variant,
					},
				)}
				href={`${button.url || ''}`}
				target={button.target}
			>
				{button.add && (
					<ContentItem nodeId={button?.add?.contentful_id} />
				)}
				{button.text ? <span>{button.text}</span> : null}
			</a>
		);
	}

	if (url.startsWith('#') || url.startsWith('mailto:')) {
		return (
			<a
				role="button"
				tabIndex="0"
				className={classNames(
					btnClasses,
					appearance(button, {
						component: 'button',
						variant: 'medium',
						buttonVariant: 'primary',
					}),
					'btn',
					{
						'bg-[#BFBFBF] text-white':
							typeof window !== 'undefined' &&
							window.location.href.includes(button.url),
						[`btn-${button?.variant?.toLowerCase()}`]:
							!!button?.variant,
					},
				)}
				href={button.url}
				onClick={(e) => {
					e.preventDefault();
					scrollToTargetAdjusted(e, url);
				}}
			>
				{button.add && (
					<ContentItem nodeId={button?.add?.contentful_id} />
				)}
				{button.text ? <span>{button.text}</span> : null}
			</a>
		);
	}

	return (
		<Link
			className={classNames(
				btnClasses,
				appearance(button, {
					component: 'button',
					variant: 'medium',
					buttonVariant: 'primary',
				}),
				'btn',
				{
					'bg-[#BFBFBF] text-white':
						typeof window !== 'undefined' &&
						window.location.href.includes(button.url),
					[`btn-${button?.variant?.toLowerCase()}`]:
						!!button?.variant,
				},
			)}
			to={trailingSlash(button.url)}
		>
			{button.add && <ContentItem nodeId={button?.add?.contentful_id} />}
			{button.text ? <span>{button.text}</span> : null}
		</Link>
	);
};

const SectionButtons = ({ buttons, className, btnClasses, onClickHandler }) => {
	return (
		<div
			className={classNames(
				'buttons flex items-center flex-row pt-8 gap-2',
				appearance(buttons.custom),
				className,
			)}
			style={appearanceStyle(buttons?.custom)}
		>
			{buttons?.button?.map((button) => (
				<SectionButton
					key={button.id}
					button={button}
					btnClasses={btnClasses}
				/>
			))}
		</div>
	);
};

export default memo(SectionButtons);
