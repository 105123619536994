import React, { useState } from 'react';
import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Quotes = ({ className, quotes }) => {
	const [activeQuote = 0, setActiveQuote] = useState(0);

	if (!quotes.add) {
		return null;
	}

	return (
		<div className={classNames(className, `quotes`)}>
			<div
				className={classNames(
					`quote ${
						quotes?.displayType?.includes('Grid')
							? 'flex justify-between'
							: ''
					}`,
				)}
			>
				{quotes?.image?.url && (
					<img
						src={quotes?.image?.url}
						alt={quotes?.image?.description}
						className="mx-auto my-16"
					/>
				)}
				{(quotes?.tab || quotes?.add)?.map((tab, index) => (
					<section
						key={tab.id}
						className={`${
							quotes?.displayType?.includes('Slider')
								? activeQuote === index
									? 'max-h-full opacity-1 lg:min-h-[320px]'
									: 'max-h-0 opacity-0'
								: 'max-h-full opacity-1 lg:min-h-[320px]'
						} transition-opacity duration-[0.5s] delay-[0s] flex flex-col justify-center overflow-hidden px-0 lg:px-32`}
						tab={index}
					>
						{tab.text && tab.text.raw ? (
							<div
								className={classNames(
									`mx-0 mt-0 mb-2 lg:mb-6 overflow-hidden [&>*]:text-[20px] [&>*]:font-normal [&>*]:leading-[110%] [&>*]:text-[#3d3d3c] [&>*]:lg:text-[32px] [&>*]:lg:font-light text-center gap-16 flex flex-col`,
								)}
							>
								{documentToReactComponents(
									JSON.parse(tab.text.raw),
								)}
							</div>
						) : null}
					</section>
				))}

				{quotes?.displayType?.includes('Slider') && (
					<ul
						className={classNames(
							'mx-0 ml-0 mt-2 lg:mt-8 p-0 flex flex-row justify-center list-none',
						)}
					>
						{(quotes?.tab || quotes?.add)?.map((tab, index) => (
							<li
								key={tab.id}
								className={classNames(
									'my-0 mx-1 p-0 only-child:hidden',
									`quote-dot-${index}`,
									// { 'quote-dot-active': activeQuote === index },
								)}
								quote={index}
							>
								<button
									className={`${
										activeQuote === index
											? 'bg-[#000000cc]'
											: 'bg-[#00000080]'
									} border-0 h-2.5 w-2.5 rounded-full cursor-pointer p-0 lg:h-2 lg:w-2`}
									type="button"
									role="tab"
									tabIndex="0"
									onClick={() => setActiveQuote(index)}
								/>
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};
Quotes.displayName = 'Quotes';
export default Quotes;
