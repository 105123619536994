import classNames from 'classnames';
import React, { useState } from 'react';
import appearance, { appearanceStyle } from '../../utils/appearance';
import ContentItem from '../ContentItem';

const Accordion = ({ className, accordion }) => {
	const activeAccordionIndex = (accordion.add || []).findIndex(
		({ active = false }) => active,
	);

	const [activeAccordion = 0, setActiveAccordion] = useState([
		activeAccordionIndex,
	]);

	if (!accordion.add) {
		return null;
	}

	return (
		<div
			className={classNames(className, appearance(accordion.custom))}
			style={appearanceStyle(accordion?.custom)}
		>
			<ul
				className={classNames(
					'flex flex-col justify-center items-center list-style-type-none overflow-hidden max-w-[80%] mt-0 mx-auto mb-10',
				)}
			>
				{accordion?.add.map((accordion, index) => (
					<li
						key={accordion.id}
						className={classNames(
							'm-0 w-full mx-0 mt-0 mb-4 justify-center items-center text-center border rounded-lg p-7',
							{
								[`text-align-${accordion.textAlign}`]:
									accordion.textAlign,
							},
						)}
					>
						<button
							type="button"
							role="tab"
							tabIndex="0"
							className={classNames(
								"flex flex-row justify-center items-center text-center mt-0 mb-2 mx-auto min-h-[40px] px-7 text-[#7e8890] cursor-pointer text-[16px] font-semibold leading-[100%] bg-transparent w-full after:bg-accordion-icon after:ml-auto after:content-[''] after:block after:h-[24px] after:w-[24px] hover:text-[#3d3d3c]",
								{
									'text-[#3d3d3c] after:rotate-180':
										activeAccordion.includes(index),
								},
							)}
							onClick={() =>
								setActiveAccordion(
									activeAccordion.includes(index)
										? [
												...activeAccordion.filter(
													(item) => item !== index,
												),
										  ]
										: [...activeAccordion, index],
								)
							}
						>
							{accordion.title || ''}
						</button>
						<div
							key={accordion.id}
							className={classNames('text-left py-0 px-7', {
								hidden: !activeAccordion.includes(index),
							})}
						>
							{accordion?.add?.map((item) => (
								<ContentItem
									key={item.id}
									nodeId={item.contentful_id}
								/>
							))}
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

Accordion.displayName = 'Accordion';
export default Accordion;
