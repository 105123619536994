import React, { useState } from 'react';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { Script } from 'gatsby';
import { Link } from 'gatsby-plugin-intl';
import { COOKIE_NAME } from '../../constants/CookieName';

import { XMarkIcon } from '@heroicons/react/24/solid';

/*
OLD CSS

position: fixed;
left: 0;
right: 0;
bottom: 10px;
text-align: center;
z-index: 1100;

@media (max-width: 600px) {
	bottom: 0;
}

.notification {
	background: rgba(246, 249, 252, 0.75);
	box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
		0 3px 7px -3px rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(20px);
	color: #3d424e;
	margin: 0 auto;
	border-radius: 4px;
	display: inline-flex;
	text-align: center;
	align-items: center;
	@media (max-width: 600px) {
		background: rgba(246, 249, 252, 1);
		flex-direction: column;
		margin: 0;
		width: 100%;
		border: 0;
		border-radius: 0;
	}
	.text {
		padding: 8px 5px 8px 15px;
		margin: 0;
		font-size: 12px;
		.link {
			color: #3d424e;
			text-decoration: underline;
		}
	}
	.notification-actions {
		@media (max-width: 600px) {
			flex-direction: column;
			display: flex;
			[class*='btn'] {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	.btn-link {
		color: #3d424e;
		text-transform: uppercase;
		font-size: 12px;
	}
	.btn-primary {
		font-size: 12px;
		margin-right: 16px;
		border: 0px solid transparent;
		height: 24px;
		text-transform: uppercase;
	}
	.btn-close {
		appearance: none;
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6668 4.27333L11.7268 3.33333L8.00016 7.06L4.2735 3.33333L3.3335 4.27333L7.06016 8L3.3335 11.7267L4.2735 12.6667L8.00016 8.94L11.7268 12.6667L12.6668 11.7267L8.94016 8L12.6668 4.27333Z' fill='%23ACACAC'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center center;
		background-color: transparent;
		border: none;
		outline: none;
		user-select: none;
		position: relative;
		width: 24px;
		height: 24px;
		transition: color 0.15s;
		cursor: pointer;
		padding: 0;
		margin-right: 8px;
	}
}
*/

const Notification = ({ className }) => {
	const [show, setShow] = useState(true);
	const [showNotification, setShowNotification] = useState(true);

	if (show === false && Cookies.get(COOKIE_NAME) === 'false') {
		return null;
	}

	if (Cookies.get(COOKIE_NAME) === 'true') {
		return (
			<Script id="tracker">{`(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('ywVkO4XKjX17Z6Bj');`}</Script>
		);
	}

	return (
		<>
			{showNotification && (
				<div
					className={classNames(
						className,
						`notification-wrapper fixed bottom-0 left-0 right-0 text-center z-50 p-5 bg-white shadow border-t`,
					)}
				>
					<div
						className={classNames(
							`notification flex flex-col gap-3 items-center justify-center text-center relative`,
						)}
					>
						<button
							className={classNames(
								'border-0 bg-transparent z-[999] absolute top-0 right-0 flex',
							)}
							onClick={() => setShowNotification(false)}
						>
							<XMarkIcon className="h-5 w-5 dark:text-gray-500" />
						</button>
						<p className="text-sm lg:text-base">
							By using this website, you agree to our{' '}
							<Link className="link underline" to="/cookies">
								cookie&nbsp;policy
							</Link>
						</p>
						<div className="notification-actions flex p-1 flex-row gap-3">
							<button
								className="btn btn-link btn-small"
								onClick={() => {
									Cookies.set(COOKIE_NAME, 'false');
									setShow(false);
								}}
							>
								<span>Decline</span>
							</button>
							<button
								className="btn btn-primary btn-small"
								onClick={() => {
									try {
										Cookies.set(COOKIE_NAME, 'true');
										window.trackGoogleAnalytics();
										window.trackHotjar();
									} catch (e) {
										console.log(e);
									}
									setShow(false);
								}}
							>
								<span>Accept</span>
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

Notification.displayName = 'Notification';
export default Notification;
