import classNames from 'classnames';
import React, { useState } from 'react';
import appearance, { appearanceStyle } from '../../utils/appearance';
import ContentItem from '../ContentItem';

const Tabs = ({ className, tabs }) => {
	const activeTabIndex = (tabs?.tabs || []).findIndex(
		({ active = false }) => active,
	);

	const [activeTab = 0, setActiveTab] = useState(
		activeTabIndex === -1 ? 0 : activeTabIndex,
	);

	if (!tabs.tabs) {
		return null;
	}

	return (
		<div
			className={classNames(
				className,
				'text-center mt-6',
				tabs?.custom?.container,
				appearance(tabs.custom),
			)}
			style={appearanceStyle(tabs?.custom)}
		>
			<ul
				className={classNames(
					'flex justify-center items-center text-center mb-12 mx-auto text-gray-900 bg-gray-100 rounded-full w-fit hidden md:flex',
				)}
			>
				{(tabs.tabs || [])?.map((tab, index) => (
					<li
						key={tab.id}
						className={classNames(
							'tab m-0 p-0 h-full min-h-full flex',
							{
								'': activeTab === index,
							},
						)}
						tab={index}
					>
						<button
							className={classNames(
								'tab-button min-h-[40px] px-7 inline-block w-full p-4 hover:bg-black hover:text-white hover:rounded-full text-sm lg:text-base',
								{
									'bg-black text-white rounded-full':
										activeTab === index,
								},
							)}
							type="button"
							role="tab"
							tabIndex="0"
							onClick={() => setActiveTab(index)}
							// onMouseEnter={() => setActiveTab(false)}
						>
							{tab?.text || tab.name}
						</button>
					</li>
				))}
			</ul>

			<div className={classNames('tabs')}>
				{(tabs.tabs || []).map((tab, index) => (
					<section
						key={tab.id}
						className={classNames(
							// 'tab hidden',
							'flex flex-col lg:flex-row justify-center items-center text-center',
							{
								'md:hidden': activeTab !== index,
							},
							{
								[`text-align-${tab.textAlign}`]: tab.textAlign,
							},
						)}
						tab={index}
					>
						<div className="p-4 w-full">
							{tab.add.map((item) => (
								<ContentItem
									key={item.id}
									nodeId={item.contentful_id}
									root="tabs"
								/>
							))}
						</div>
					</section>
				))}
			</div>
		</div>
	);
};
Tabs.displayName = 'Tabs';
export default Tabs;
