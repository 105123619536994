/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';
import './src/styles/animate.min.css';
import './src/styles/main.scss';

import ReactDOM from 'react-dom';

export function replaceHydrateFunction() {
	return (element, container, callback) => {
		ReactDOM.render(element, container, callback);
	};
}
