import React from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import './style.css';

const Forms = () => {
	// Define your email details
	const emailAddress = 'info@asteria.ai';
	const subject = encodeURIComponent('Inquiry from Asteria Homepage');
	const emailBody = encodeURIComponent(`Hello Asteria Team`);

	const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${emailBody}`;

	return (
		<>
			<a
				href={mailtoLink}
				className="btn-medium btn-primary btn btn-medium w-fit btn-contact flex flex-row gap-4"
			>
				Contact us
				<EnvelopeIcon className="h-5 w-5 dark:text-gray-50" />
			</a>
		</>
	);
};

export default Forms;
