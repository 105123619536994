import React, { useContext } from 'react';
import ContentItem from '../ContentItem';
import classNames from 'classnames';
import appearance, { appearanceStyle } from '../../utils/appearance';
import Context from '../../context';

const GridItem = ({ item, className, root }) => {
	const { nodes } = useContext(Context);
	let apperanceItem = item?.custom || {};
	let apperanceId = item?.add?.find?.(
		({ __typename }) => __typename === 'ContentfulAppearance',
	);

	if (apperanceId) {
		apperanceItem = { ...apperanceItem, ...nodes[apperanceId?.id] };
	}

	return (
		<div
			className={classNames(
				className,
				'flex',
				'flex-col',
				item?.column,
				appearance(apperanceItem),
			)}
			style={appearanceStyle(apperanceItem)}
		>
			{item?.add?.map((item) => (
				<ContentItem
					key={item.id}
					nodeId={item.contentful_id}
					root={root}
				/>
			))}
		</div>
	);
};

const Grid = ({ grid, className, root }) => {
	const { add: items = [] } = grid;

	return (
		<div
			className={classNames(
				grid.grid ||
					'flex flex-col lg:grid lg:grid-flow-col gap-8 lg:gap-16 lg:auto-cols-fr',
				className,
				appearance(grid.custom, {
					verticalAlign: 'justify-items-center',
					horizontalAlign: 'items-center',
				}),
			)}
			style={appearanceStyle(grid?.custom)}
		>
			{items.map((item) => (
				<GridItem key={item.id} item={item} root={root} />
			))}
		</div>
	);
};

export default Grid;
