import classNames from 'classnames';
import React, { useState } from 'react';
import appearance, { appearanceStyle } from '../../utils/appearance';
import ContentItem from '../ContentItem';

const Slides = ({ section }) => {
	const [activeSlide = 0, setActiveSlide] = useState(0);
	let currentSlide = section?.add?.[activeSlide] || {};
	let currentImage =
		(currentSlide &&
			currentSlide?.add?.find((item) => !!item?.image?.url)?.image
				?.url) ||
		'';

	let currentColor =
		currentSlide?.custom?.backgroundColor ||
		currentSlide?.add?.find((item) => !!item?.custom?.backgroundColor)
			?.custom?.backgroundColor ||
		'';

	return (
		<div
			className={classNames(
				`bg-[${currentColor}]`,
				appearance(section.custom),
			)}
			style={{
				backgroundImage: `url(${currentImage})`,
				...appearanceStyle(section?.custom),
			}}
		>
			<div
				className={classNames(
					`quotes ${
						section?.displayType?.includes('Grid')
							? 'flex justify-between'
							: ''
					} 
          `,
				)}
			>
				{(section?.tab || section?.add)?.map((tab, index) => (
					<section
						key={tab.id}
						className={classNames(
							'transition-opacity duration-[0.5s] delay-[0s] flex flex-col justify-center items-center overflow-hidden',
							{
								'max-h-full opacity-1 lg:min-h-[320px]':
									activeSlide === index,
								'max-h-0 opacity-0': activeSlide !== index,
							},
						)}
					>
						{tab?.add?.map((item) => (
							<ContentItem
								key={item.id}
								nodeId={item.contentful_id}
							/>
						))}
					</section>
				))}

				<ul
					className={classNames(
						'mx-0 ml-0 mt-2 lg:mt-8 p-0 flex flex-row justify-center list-none',
					)}
				>
					{(section?.tab || section?.add)?.map((tab, index) => (
						<li
							key={tab.id}
							className={classNames(
								'my-0 mx-1 p-0 only-child:hidden',
								`quote-dot-${index}`,
								// { 'quote-dot-active': activeSlide === index },
							)}
							quote={index}
						>
							<button
								className={`${
									activeSlide === index
										? 'bg-[#000000cc]'
										: 'bg-[#00000080]'
								} border-0 h-2.5 w-2.5 rounded-full cursor-pointer p-0 lg:h-2 lg:w-2`}
								type="button"
								role="tab"
								tabIndex="0"
								onClick={() => setActiveSlide(index)}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default Slides;
