import classNames from 'classnames';
import { format, parseISO } from 'date-fns';

import React from 'react';
import appearance, { appearanceStyle } from '../../utils/appearance';

const Meta = ({ item }) => {
	if (!item) {
		return null;
	}

	return (
		<div
			className={classNames('meta', appearance(item?.custom))}
			style={{ ...appearanceStyle(item?.custom) }}
		>
			<p className="text-sm text-typography-normal-muted">
				{format(parseISO(item.date), 'yyyy-MM-dd')}
			</p>
		</div>
	);
};

export default Meta;
